import React, { useState } from 'react';

const VideoForm = ({ onSubmit }) => {
    const [videoId, setVideoId] = useState('');
    const [start, setStart] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit({ videoId, start: parseInt(start, 10) });
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                value={videoId}
                onChange={(e) => setVideoId(e.target.value)}
                placeholder="YouTube Video ID"
                required
            />
            <input
                type="number"
                value={start}
                onChange={(e) => setStart(e.target.value)}
                placeholder="Start Time (in seconds)"
                required
            />
            <button type="submit">Play Video</button>
        </form>
    );
};

export default VideoForm;
