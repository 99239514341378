import React, { useState, useEffect } from 'react';
import VideoPlayer from './VideoPlayer';
import VideoForm from './VideoForm';

function App() {
  const [videoInfo, setVideoInfo] = useState(() => {
    const savedVideoInfo = localStorage.getItem('videoInfo');
    return savedVideoInfo ? JSON.parse(savedVideoInfo) : { videoId: '', start: 0 };
  });

  useEffect(() => {
    localStorage.setItem('videoInfo', JSON.stringify(videoInfo));
  }, [videoInfo]);

  const handleVideoSubmit = (info) => {
    setVideoInfo(info);
  };

  return (
    <div className="App">
      <h1>React YouTube Player</h1>
      <VideoForm onSubmit={handleVideoSubmit} />
      {videoInfo.videoId && (
        <VideoPlayer videoId={videoInfo.videoId} start={videoInfo.start} />
      )}
    </div>
  );
}

export default App;
