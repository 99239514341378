import React from 'react';

const VideoPlayer = ({ videoId, start }) => {
    const videoSrc = `https://www.youtube.com/embed/${videoId}?start=${start}`;

    return (
        <div className="video-container" style={{ position: 'relative', paddingBottom: '56.25%', height: '0' }}>
            <iframe
                title="YouTube Video Player"
                src={videoSrc}
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                frameBorder="0"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default VideoPlayer;
